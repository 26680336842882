import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Text, Div, Anchor } from "atomize"
import "../css/global.css"
const Impressum = ({ data }) => (
  <Layout>
    <SEO
      title="Corona Navi | Datenschutzhinweise"
      description="Deine zentrale Anlaufstelle für alle Themen rund um Corona."
    />
    <Div tag="section" p={{ t: { xs: "6rem", md: "10rem" } }}>
      <Container>
        <Text
          textWeight="500"
          textSize={{ xs: "title", sm: "heading", md: "display1" }}
          fontFamily="primary"
          m={{ b: "1rem" }}
        >
          Datenschutzhinweise corona-navi.de
        </Text>
        <Text tag="p">
          Datenschutz hat einen besonders hohen Stellenwert für die
          Geschäftsleitung der Convaise UG (haftungsbeschränkt) (im Folgenden
          Convaise). Eine Nutzung der Internetseiten von Convaise (zu der
          corona-navi.de gehört) ist grundsätzlich ohne jede Angabe
          personenbezogener Daten möglich. Sofern eine betroffene Person
          besondere Services oder Dienstleistungen unseres Unternehmens über
          unsere Internetseite in Anspruch nehmen möchte, kann eine Verarbeitung
          personenbezogener Daten erforderlich werden. Ist die Verarbeitung
          personenbezogener Daten erforderlich und besteht für eine solche
          Verarbeitung keine gesetzliche Grundlage, holen wir generell eine
          Einwilligung der betroffenen Person ein. Die Verarbeitung
          personenbezogener Daten, beispielsweise des Namens, der Anschrift,
          E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt
          stets im Einklang mit der Datenschutz-Grundverordnung und in
          Übereinstimmung mit den für Convaise geltenden landesspezifischen
          Datenschutzbestimmungen. Mittels dieser Datenschutzhinweise möchte
          unser Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der
          von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
          informieren. Ferner werden betroffene Personen mittels dieser
          Datenschutzhinweise über die ihnen zustehenden Rechte aufgeklärt.{" "}
          <br />
          <br />
          Convaise hat als für die Verarbeitung Verantwortlicher zahlreiche
          technische und organisatorische Maßnahmen umgesetzt, um einen
          möglichst lückenlosen Schutz der über diese Internetseite
          verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können
          Internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken
          aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden
          kann.
        </Text>
        <Text textWeight="500" p={{ t: "1rem" }}>
          Verantwortliche Stelle für die Datenverarbeitung:
        </Text>
        <Text>
          Convaise UG (haftungsbeschränkt)
          <br />
          Atelierstraße 29
          <br />
          81671 München <br />
          E-Mail:{" "}
          <Anchor href="mailto:kontakt@convaise.com">
            kontakt@convaise.com
          </Anchor>
        </Text>
        <Text
          textSize="heading"
          p={{ t: { xs: "1rem", md: "2rem" }, b: "1rem" }}
          fontFamily="primary"
        >
          1. Datenschutzbeauftragter
        </Text>
        <Text>
          Wir haben für unser Unternehmen einen Datenschutzbeauftragten
          bestellt.
          <br />
          Kontakt:{" "}
          <Anchor href="datenschutz@convaise.com">
            datenschutz@convaise.com
          </Anchor>
        </Text>
        <Text
          textSize="heading"
          p={{ t: { xs: "1rem", md: "2rem" } }}
          fontFamily="primary"
        >
          2. Bereitstellung der Webseite
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Erfassung von allgemeinen Daten und Informationen:
        </Text>
        <Text>
          Die Internetseite von Convaise erfasst mit jedem Aufruf der
          Internetseite durch eine betroffene Person oder ein automatisiertes
          System eine Reihe von allgemeinen Daten und Informationen. Diese
          allgemeinen Daten und Informationen werden in den Logfiles des Servers
          gespeichert. Erfasst werden können:
        </Text>
        <ul>
          <li>die verwendeten Browsertypen und Versionen,</li>
          <li>das vom zugreifenden System verwendete Betriebssystem,</li>
          <li>
            die Internetseite, von welcher ein zugreifendes System auf unsere
            Internetseite gelangt (sogenannte Referrer),
          </li>
          <li>
            die Unterwebseiten, welche über ein zugreifendes System auf unserer
            Internetseite angesteuert werden,
          </li>
          <li>
            das Datum und die Uhrzeit eines Zugriffs auf die Internetseite,
          </li>
          <li>eine Internet-Protokoll-Adresse (IP-Adresse),</li>
          <li>der Internet-Service-Provider des zugreifenden Systems</li>
          <li>
            und sonstige ähnliche Daten und Informationen, die der
            Gefahrenabwehr im Falle von Angriffen auf unsere
            informationstechnologischen Systeme dienen.
          </li>
        </ul>
        <Text>
          Bei der Nutzung dieser allgemeinen Daten und Informationen zieht
          Convaise keine Rückschlüsse auf die betroffene Person. Diese
          Informationen werden vielmehr benötigt, um
        </Text>
        <ul>
          <li>die Inhalte unserer Internetseite korrekt auszuliefern,</li>
          <li>die Inhalte unserer Internetseite zu optimieren,</li>
          <li>
            die dauerhafte Funktionsfähigkeit unserer
            informationstechnologischen Systeme und der Technik unserer
            Internetseite zu gewährleisten sowie
          </li>
          <li>
            um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
            Strafverfolgung notwendigen Informationen bereitzustellen.
          </li>
        </ul>
        <Text>
          Diese anonym erhobenen Daten und Informationen werden durch Convaise
          daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den
          Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen,
          um letztlich ein optimales Schutzniveau für die von uns verarbeiteten
          personenbezogenen Daten sicherzustellen. Die anonymen Daten der
          Server-Logfiles werden getrennt von allen durch eine betroffene Person
          angegebenen personenbezogenen Daten gespeichert.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Berechtigte Interessen an der Verarbeitung, die von dem
          Verantwortlichen oder Dritten verfolgt werden:
        </Text>
        <Text>
          Die oben aufgelisteten Daten sind notwendig für die Bereitstellung
          unserer Webseite. Unsere Rechtsgrundlage ist unser berechtigtes
          Interesse (Art. 6 Abs. 1 lit. f der DSGVO) unser Unternehmen modern
          darzustellen und einem breiten Publikum verfügbar zu machen. Die
          erhobenen Daten sind technisch zwingend nötig, damit wir Ihnen die
          Webseite ansprechend zu Verfügung stellen können.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Cookies und Reichweitenmessung:
        </Text>
        <Text>
          Die Internetseite www.corona-navi.de von Convaise verwendet keine
          Cookies. Wir nutzen den Dienst Plausible Analytics zur
          Reichweitenmessung auf dieser Webseite. Der Dienst wird zur Verfügung
          gestellt durch Plausible Insights OÜ, Västriku tn 2, 50403, Tartu,
          Estland ("Plausible").
          <br />
          <br />
          Durch das Nutzen des Dienstes baut Ihr Browser eine Verbindung zu den
          Servern von Plausible auf, dort werden bestimmte Daten des
          HTTP-Headers gespeichert, damit wir nachvollziehen können woher unsere
          Besucher kommen, welche Systeme sie einsetzen und welche unserer
          Webseiten besonders attraktiv sind. Wir nutzen diese Informationen, um
          unser Online-Angebot nutzerfreundlicher zu gestalten und zu messen wie
          erfolgreich unsere Marketing-Kampagnen sind. Die Rechtsgrundlage
          hierfür ist unser berechtigtes Interesse (Art. 6 Abs. 1 lit. f der
          DSGVO) eine moderne und attraktive Präsenz im Internet zu betreiben,
          nachzuvollziehen was unsere Kunden interessiert und unsere
          Marketingmaßnahmen effektiver zu gestalten.
          <br />
          <br />
          Die IP-Adresse des Nutzers wird nicht direkt gespeichert, sondern über
          ein gesondertes Verfahren anonymisiert. Wir erhalten anschließend nur
          eine aggregierte Übersicht. Wir haben mit Plausible einen Vertrag zu
          Auftragsverarbeitung abgeschlossen.
          <br />
          <br />
          Weiter Informationen zu Plausible finden Sie hier:{" "}
          <Anchor href="https://plausible.io/data-policy">
            https://plausible.io/data-policy
          </Anchor>
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Bestehen einer automatisierten Entscheidungfindung:
        </Text>
        <Text>
          Bei der Darstellung unserer Webseiten findet keine automatisierte
          Entscheidungsfindung statt.
        </Text>
        <Text
          textSize="heading"
          p={{ t: { xs: "1rem", md: "2rem" }, b: "1rem" }}
          fontFamily="primary"
          id="kontaktformular"
        >
          3. Kontaktformular
        </Text>
        <Text>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
          Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
          nicht ohne Ihre Einwilligung weiter. <br /> Die Verarbeitung der in
          das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich
          auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie
          können diese Einwilligung jederzeit widerrufen. Dazu reicht eine
          formlose Mitteilung per E-Mail an{" "}
          <Anchor href="mailto:datenschutz@convaise.com">
            datenschutz@convaise.com
          </Anchor>
          . Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von
          Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis
          Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
          widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
          abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
          Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
        </Text>
        <Text
          textSize="heading"
          p={{ t: { xs: "1rem", md: "2rem" }, b: "1rem" }}
          fontFamily="primary"
          id="assistenten"
        >
          4. Datenschutzbestimmungen über die Anwendung und Nutzung der Convaise
          Assistenten
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Art und Zweck der Verarbeitung:
        </Text>
        <Text>
          Die von Ihnen potenziell eingegebenen Postleitzahlen werden verwendet,
          um die von Ihnen gewählten Themen oder Informationen passend zu Ihrem
          Standord basierend auf der angegebenen Postleitzahl zu ermitteln.
          Dementsprechend verarbeiten wir die Postleitzahl, um die von Ihnen
          gewählte Dienstleistung bereistzustellen.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Rechtsgrundlage:
        </Text>
        <Text>
          Die Verarbeitung der Daten erfolgt auf Grundlage einer Einwilligung
          des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Empfänger:
        </Text>
        <Text>
          Für die verschlüsselte Übertragung der Nachrichten zwischen dem
          digitalen Assistenten von Convaise und dem Nutzer wird ein in der EU
          gehosteter Service von der Microsoft Corporation, One Microsoft Way
          Redmond, WA 98052-6399 USA ("Microsoft") verwendet.
          <br />
          Zum Testen der Verbindungsmöglichkeiten, z.B. im Falle des Zugriffs in
          einem Unternehmensnetzwerk mit Beschränkungen, wird beim
          Verbindungsaufbau mit dem Assistenten einmal versucht eine Verbindung
          zu websocket.org zu erstellen. Basierend auf dieser Verbindung
          entscheidet der Assistent welche Verbindungsart für Ihre Situation am
          besten geeignet ist. Bei dieser temporären Verbindung erhält
          websocket.org nur Ihre IP-Adresse.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Speicherdauer:
        </Text>
        <Text>
          Konversationsnachrichten mit dem digitalen Assistenten werden nach
          spätestens 7 Tagen gelöscht. In dieser Zeit sind sie verschlüsselt und
          für uns nicht zugreifbar gespeichert, um die Konversation zwischen dem
          Nutzer und dem Assistenten zu ermöglichen.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Bereitstellung vorgeschrieben oder erforderlich:
        </Text>
        <Text>
          Die Bereitstellung Ihrer Postleitzahl erfolgt freiwillig. Wir können
          Ihre Anfrage jedoch nur korrekt bearbeiten, sofern Sie die von dem
          digitalen Assistenten geforderten Daten korrekt eingeben.
        </Text>
        <Text
          textSize="heading"
          p={{ t: { xs: "1rem", md: "2rem" }, b: "1rem" }}
          fontFamily="primary"
        >
          5. Routinemäßige Löschung und Sperrung von personenbezogenen Daten
        </Text>
        <Text>
          Der für die Verarbeitung Verantwortliche verarbeitet und speichert
          personenbezogene Daten der betroffenen Person nur für den Zeitraum,
          der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern
          dies durch den Europäischen Richtlinien- und Verordnungsgeber oder
          einen anderen Gesetzgeber in Gesetzen oder Vorschriften, welchen der
          für die Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.
          <br />
          <br />
          Entfällt der Speicherungszweck oder läuft eine vom Europäischen
          Richtlinien- und Verordnungsgeber oder einem anderen zuständigen
          Gesetzgeber vorgeschriebene Speicherfrist ab, werden die
          personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen
          Vorschriften gesperrt oder gelöscht.
        </Text>
        <Text
          textSize="heading"
          p={{ t: { xs: "1rem", md: "2rem" }, b: "1rem" }}
          fontFamily="primary"
        >
          6. Rechte der betroffenen Person
        </Text>
        <Text>
          Werden personenbezogene Daten des Nutzers verarbeitet, ist dieser
          Betroffener i. S. d. DSGVO und es stehen ihm die nachfolgende Rechte
          gegenüber dem Verantwortlichen zu:
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          6.1. Auskunftsrecht
        </Text>
        <Text>
          Der Nutzer kann von Convaise eine Bestätigung darüber verlangen, ob
          personenbezogene Daten, die ihn betreffen, von Convaise verarbeitet
          werden. Liegt eine solche Verarbeitung vor, kann der Nutzer über
          folgende Informationen bei jedem Mitarbeiter von Convaise z.B. via
          E-Mail an datenschutz@convaise.com Auskunft verlangen:
          <ul>
            <li>
              die Zwecke, zu denen die personenbezogenen Daten verarbeitet
              werden;
            </li>
            <li>
              die Kategorien von personenbezogenen Daten, welche verarbeitet
              werden;
            </li>
            <li>
              die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen
              die Sie betreffenden personenbezogenen Daten offengelegt wurden
              oder noch offengelegt werden;
            </li>
            <li>
              die geplante Dauer der Speicherung der den Nutzer betreffenden
              personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
              möglich sind, Kriterien für die Festlegung der Speicherdauer;
            </li>
            <li>
              das Bestehen eines Rechts auf Berichtigung oder Löschung der den
              Nutzer betreffenden personenbezogenen Daten, eines Rechts auf
              Einschränkung der Verarbeitung durch Convaise oder eines
              Widerspruchsrechts gegen diese Verarbeitung;
            </li>
            <li>
              das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
            </li>
            <li>
              alle verfügbaren Informationen über die Herkunft der Daten, wenn
              die personenbezogenen Daten nicht bei der betroffenen Person
              erhoben werden;
            </li>
            <li>
              das Bestehen einer automatisierten Entscheidungsfindung
              einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und –
              zumindest in diesen Fällen – aussagekräftige Informationen über
              die involvierte Logik sowie die Tragweite und die angestrebten
              Auswirkungen einer derartigen Verarbeitung für die betroffene
              Person.
            </li>
          </ul>
          Darüber hinaus hat der Nutzer das Recht eine Kopie der
          personenbezogenen Daten zu erhalten, die Gegenstand der Verarbeitung
          durch Convaise sind.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          6.2. Recht auf Berechtigung
        </Text>
        <Text>
          Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
          gegenüber Convaise, sofern die verarbeiteten personenbezogenen Daten,
          die ihn betreffen, unrichtig oder unvollständig sind. Convaise hat die
          Berichtigung unverzüglich vorzunehmen.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          6.3. Recht auf Einschränkung der Verarbeitung
        </Text>
        <Text>
          Unter den folgenden Voraussetzungen kann der Nutzer die Einschränkung
          der Verarbeitung der ihn betreffenden personenbezogenen Daten
          verlangen:
          <ul>
            <li>
              wenn der Nutzer die Richtigkeit der ihn betreffenden
              personenbezogenen für eine Dauer bestreitet, die es dem
              Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen
              Daten zu überprüfen;
            </li>
            <li>
              die Verarbeitung unrechtmäßig ist und der Nutzer die Löschung der
              personenbezogenen Daten ablehnt und stattdessen die Einschränkung
              der Nutzung der personenbezogenen Daten verlangt;
            </li>
            <li>
              Convaise die personenbezogenen Daten für die Zwecke der
              Verarbeitung nicht länger benötigt, Sie diese jedoch zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              benötigen, oder
            </li>
            <li>
              wenn der Nutzer Widerspruch gegen die Verarbeitung gemäß Art. 21
              Abs. 1 DSGVO eingelegt hat und noch nicht feststeht, ob die
              berechtigten Gründe von Convaise oder eines Dritten gegenüber
              seinen Gründen überwiegen.
            </li>
          </ul>
          Wurde die Verarbeitung der den Nutzer betreffenden personenbezogenen
          Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung
          abgesehen – nur mit seiner Einwilligung oder zur Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
          Rechte einer anderen natürlichen oder juristischen Person oder aus
          Gründen eines wichtigen öffentlichen Interesses der Union oder eines
          Mitgliedstaats verarbeitet werden. Wurde die Einschränkung der
          Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie
          von Convaise unterrichtet bevor die Einschränkung aufgehoben wird.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          6.4. Recht auf Löschung (Recht auf Vergessen werden)
        </Text>
        <Text>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, von dem Verantwortlichen zu verlangen, dass die sie
          betreffenden personenbezogenen Daten unverzüglich gelöscht werden,
          sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung
          nicht erforderlich ist:
          <ul>
            <li>
              Die personenbezogenen Daten wurden für solche Zwecke erhoben oder
              auf sonstige Weise verarbeitet, für welche sie nicht mehr
              notwendig sind.
            </li>
            <li>
              Die betroffene Person widerruft ihre Einwilligung, auf die sich
              die Verarbeitung gemäß Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs.
              2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen
              Rechtsgrundlage für die Verarbeitung.
            </li>
            <li>
              Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch
              gegen die Verarbeitung ein, und es liegen keine vorrangigen
              berechtigten Gründe für die Verarbeitung vor, oder die betroffene
              Person legt gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die
              Verarbeitung ein.
            </li>
            <li>
              Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
            </li>
            <li>
              Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
              rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
              Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
            </li>
            <li>
              Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste
              der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
            </li>
          </ul>
          Sofern einer der oben genannten Gründe zutrifft und eine betroffene
          Person die Löschung von personenbezogenen Daten, die bei Convaise
          gespeichert sind, veranlassen möchte, kann sie sich hierzu jederzeit
          an einen Mitarbeiter von Convaise wenden z.B. via E-Mail an{" "}
          <Anchor href="mailto:datenschutz@convaise.com">
            datenschutz@convaise.com
          </Anchor>{" "}
          und dieser wird veranlassen, dass dem Löschverlangen unverzüglich
          nachgekommen wird. Wurden die personenbezogenen Daten von Convaise
          öffentlich gemacht und ist unser Unternehmen als Verantwortlicher
          gemäß Art. 17 Abs. 1 DSGVO zur Löschung der personenbezogenen Daten
          verpflichtet, so trifft Convaise unter Berücksichtigung der
          verfügbaren Technologie und der Implementierungskosten angemessene
          Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung
          Verantwortliche, welche die veröffentlichten personenbezogenen Daten
          verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene Person
          von diesen anderen für die Datenverarbeitung Verantwortlichen die
          Löschung sämtlicher Links zu diesen personenbezogenen Daten oder von
          Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat,
          soweit die Verarbeitung nicht erforderlich ist. Der Mitarbeiter von
          Convaise wird im Einzelfall das Notwendige veranlassen.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          6.5. Recht auf Datenübertragbarkeit
        </Text>
        <Text>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, die sie betreffenden personenbezogenen Daten, welche durch die
          betroffene Person einem Verantwortlichen bereitgestellt wurden, in
          einem strukturierten, gängigen und maschinenlesbaren Format zu
          erhalten. Sie hat außerdem das Recht, diese Daten einem anderen
          Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
          personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
          die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO
          oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gemäß Art. 6
          Abs. 1 lit. b DSGVO beruht und die Verarbeitung mithilfe
          automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für
          die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
          Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche
          dem Verantwortlichen übertragen wurde. Ferner hat die betroffene
          Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß
          Art. 20 Abs. 1 DSGVO das Recht, zu erwirken, dass die
          personenbezogenen Daten direkt von einem Verantwortlichen an einen
          anderen Verantwortlichen übermittelt werden, soweit dies technisch
          machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer
          Personen beeinträchtigt werden. Zur Geltendmachung des Rechts auf
          Datenübertragbarkeit kann sich die betroffene Person jederzeit an
          einen Mitarbeiter von Convaise wenden z.B. via E-Mail an{" "}
          <Anchor href="mailto:datenschutz@convaise.com">
            datenschutz@convaise.com
          </Anchor>
          .
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          6.6. Widerspruchsrecht
        </Text>
        <Text>
          Der Nutzer hat das Recht, aus Gründen, die sich aus seiner besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung der ihn
          betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
          lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch
          für ein auf diese Bestimmungen gestütztes Profiling. Convaise
          verarbeitet die den Nutzer betreffenden personenbezogenen Daten nach
          einem erfolgten Widerspruch nicht mehr, es sei denn, Convaise kann
          zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
          die Interessen, Rechte und Freiheiten des Nutzers überwiegen, oder die
          Verarbeitung dient der Geltendmachung, Aus-übung oder Verteidigung von
          Rechtsansprüchen. Zur Ausübung des Widerspruchsrecht kann sich ein
          Nutzer jederzeit an einen Mitarbeiter von Convaise z.B. per E-Mail an{" "}
          <Anchor href="mailto:datenschutz@convaise.com">
            datenschutz@convaise.com
          </Anchor>{" "}
          wenden. Um die Bearbeitung des Widerspruchs zu ermöglichen, muss sich
          der Nutzer dabei entsprechend seiner ursprünglichen Anfrage
          identifizieren.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          6.7. Recht auf Beschwerde bei einer Aufsichtsbehörde
        </Text>
        <Text>
          Jede betroffene Person hat unbeschadet eines anderweitigen
          verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf
          Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes, wenn die betroffene Person
          der Ansicht ist, dass die Verarbeitung der sie betreffenden
          personenbezogenen Daten gegen diese Verordnung verstößt. Die
          Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
          unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse
          der Beschwerde einschließlich der Möglichkeit eines gerichtlichen
          Rechtsbehelfs nach Art. 78 DSGVO.
        </Text>
        <Text textSize="subheader" p={{ t: "2rem" }}>
          Stand: {data.site.buildTime}
        </Text>
      </Container>
    </Div>
  </Layout>
)

export default Impressum

export const query = graphql`
  {
    site {
      buildTime(formatString: "DD.MM.YYYY")
    }
  }
`
